<template>
<div>
    <v-loading v-if="isloading"></v-loading>

    <section class="gradient-form bg-gray-200 h-screen">
        <div class="container">
            <div class="flex justify-center items-center flex-wrap h-screen text-gray-800">
                <div class="w-full md:w-2/4">
                    <div class="block bg-white shadow-lg rounded-lg">
                        <div class="lg:flex lg:flex-wrap g-0">
                            <div class="w-full px-4 md:px-0">
                                <div class="p-10 md:p-12 md:mx-6">
                                    <div class="text-center mb-12 pb-1">
                                        <img class="mx-auto w-48" :src="LogoPadma" alt="logo" />
                                    </div>
                                    <form @submit.prevent="submitForm">
                                        <p class="mb-4">Please login to your account</p>
                                        <div class="mb-4">
                                            <input type="email" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleFormControlInput1" placeholder="Your Email" v-model="form.email" />
                                        </div>
                                        <div class="mb-4">
                                            <input type="password" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleFormControlInput1" placeholder="Password" v-model="form.password" />
                                        </div>
                                        <div class="form-group mb-5">
                                            <div ref="recaptcha" class="g-recaptcha" :data-sitekey="siteKey"></div>
                                        </div>
                                        <div class="text-center pt-1 mb-12 pb-1">
                                            <button class="inline-block px-6 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3" type="submit" data-mdb-ripple="true" data-mdb-ripple-color="light" :disabled="loading">
                                                {{loading ? 'Loading...':'Login'}}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import { masterSiteKey } from '@/main.js';
import store from '@/store';
import Logo from '../../assets/logo-padma.png'
import SecureLS from "secure-ls"
export default {
    name: 'ClientLoginView',

    data() {
        return {
            isloading : false,
            siteKey: masterSiteKey, // Ganti dengan kunci situs reCAPTCHA Anda
            recaptchaWidgetId: null,
            LogoPadma: Logo,
            form: {
                email: '',
                password: '',
                device_name: 'browser'
            },
        };
    },

    mounted() {
        this.loadRecaptcha();
    },

    methods: {

        loadRecaptcha() {
            if (window.grecaptcha) {
                this.recaptchaWidgetId = grecaptcha.render(this.$refs.recaptcha, {
                    sitekey: this.siteKey,
                    callback: this.recaptchaCallback
                });
            } else {
                setTimeout(this.loadRecaptcha, 100);
            }
        },
        recaptchaCallback(response) {
            console.log('reCAPTCHA verified:', response);
        },

        submitForm() {
            const response = grecaptcha.getResponse(this.recaptchaWidgetId);

            if (!response) {
                this.$notify({
                    title: "Error",
                    type: "error",
                    text: "Please verify reCAPTCHA"
                });
                return;
                
            } else {
                this.isloading = true;
                store.commit('ChangeLoader', true)
                const headers = {
                    'Content-Type': 'text/plain',
                    'Access-Control-Allow-Origin': '*'
                }

                this.axios.post('api/login', this.form, headers).then((response) => {
                    let obj = {
                        'token': response.data.token,
                        'permissions': response.data.role.permissions,
                        'user': JSON.stringify(response.data.user)
                    }
                    const ls = new SecureLS({ isCompression: false })
                    ls.set('session', { data: obj });
                    this.$router.push({ path: '/admin/dashboard' });
                }).catch(error => {

                    alert(error.message)

                }).finally(() => {
                    this.isloading = false;
                    store.commit('ChangeLoader', false)
                })
            }
        },
    },

    computed: {
        loading() {
            return store.state.preloader
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
