<template>
    <div class="bg-gray-900">
        <div class="grid grid-cols-1 w-full">
            <div class="w-full md:order-1 hidden container-y lg:flex items-center justify-between box-border h-10 bg-primary  dark:border-b  dark:border-gray-800 dark:bg-gray-900 text-white text-xs font-semibold dark:font-medium">
                <div class="flex items-center justify-start w-full">                    
                    <div class="flex items-center justify-center mr-4" v-if="company.length > 0">
                        <mdicon name="map-marker" size="25" class="mr-1" />
                        <div>{{company[0].address}}</div> 
                    </div>
                    <div class="flex items-center justify-center" v-if="company.length > 0">
                        <mdicon name="phone" size="25" class="mr-1" />
                        <div>{{company[0].phone}}</div> 
                    </div>
                </div>
                <div class="flex items-center justify-between w-5/6">
                    <div class="text-white h-full"><router-link to="/suku-bunga">Suku bunga</router-link></div>
                    <div class="text-white h-full"><router-link to="/karir">Karir</router-link></div>
                    <div class="text-white h-full"><router-link to="/lokasi-kantor">Lokasi Kantor</router-link></div>
                    <div class="text-white h-full"><router-link to="/blog">Blog</router-link></div>
                    <div class="text-white h-full"><router-link to="/frequently-asked-questions">FAQ</router-link></div>
                    <div class="text-white h-full"><router-link to="/asset">Promo Aset</router-link></div>
                    <mdicon name="magnify" size="25" class="cursor-pointer" @click="changesearch"/>
                    <div class="switch text-white h-full flex justify-center items-center cursor-pointer" @click="changedark">
                        <div class="h-full flex justify-center items-center">
                            <mdicon v-if="!dark" name="toggle-switch-off" size="30" class="mr-1"/>
                            <mdicon v-if="dark" name="toggle-switch" size="30" class="mr-1 cursor-pointer text-secondary"/>
                        </div>                        
                        <div  class="text-gray-200 text-xs font-medium dark:text-secondary">
                            Dark Mode
                        </div>   
                    </div>
                </div>
            </div>


            <div class="search w-full order-3 md:order-2" v-if="search">
                <form @submit.prevent="cari">
                    <input type="text" class="w-full text-sm font-normal text-center focus:outline-none bg-gray-100 dark:bg-gray-700 dark:text-gray-200 text-gray-500 duration-300 py-3 h-10"  placeholder="Cari disini...."  @change="formsearch = $event.target.value">
                </form>
            </div>


            
            <nav id="b-header" class="dark:bg-gray-900 container-y order-2 md:order-3 bg-white text-sm flex items-center h-20 md:h-28 drop-shadow-md justify-between text-primary dark:text-gray-200">
                <div class="order-last lg:order-first flex items-center justify-end lg:hidden h-full w-3/4" >
                    <div class="switch text-white h-full flex flex-col justify-center items-center cursor-pointer" @click="changedark">
                        <div class="flex justify-center items-center">
                            <mdicon v-if="!dark" name="toggle-switch-off" size="30" class="mr-1 text-primary"/>
                            <mdicon v-if="dark" name="toggle-switch" size="30" class="mr-1 cursor-pointer text-secondary"/>
                        </div>                        
                        <div  class="text-primary text-xs font-medium dark:text-secondary">
                            Dark Mode
                        </div>   
                    </div>
                </div>
                <div class="logo w-full lg:w-2/4">
                    <router-link to="/">
                        <picture>
                            <img v-if="dark" id="logo" class="logo-image" :src="LogoWhite" alt="Bank Padma">
                            <img v-if="!dark" id="logo" class="logo-image" :src="Logo" alt="Bank Padma">
                        </picture>
                    </router-link>
                </div>
                <div class="order-first lg:order-last menu flex items-center justify-start lg:justify-between text-base font-bold dark:font-semibold w-3/5">
                    <div class="hidden lg:block h-full"><router-link to="/">Beranda</router-link></div>
                    <div class="hidden lg:block h-full"><router-link to="/tabungan">Tabungan</router-link></div>
                    <div class="hidden lg:block h-full"><router-link to="/deposito">Deposito</router-link></div>
                    <div class="hidden lg:block h-full"><router-link to="/kredit">Kredit</router-link></div>
                    <div class="hidden lg:block h-full"><router-link to="/layanan">Layanan</router-link></div>
                    <div class="h-full"><mdicon name="menu" class="cursor-pointer" size="30" @click="menuopen = !menuopen"/></div>
                    <div class="h-full ml-2 block lg:hidden"><mdicon name="magnify" size="30" class="cursor-pointer" @click="changesearch"/></div>
                </div>
            </nav>
        </div>
        <Transition name="fade">
        <div v-if="menuopen" class="overlay bg-b-overlay fixed inset-0 w-full h-full z-40" @click="menuopen = false">
                
        </div>
        </Transition>

         <transition name="slide-fade">   
            <div class="side-menu overflow-y-auto lg:overflow-hidden fixed bg-white dark:bg-gray-900 drop-shadow-sm w-10/12 md:w-3/5 lg:w-96 h-full z-50 xs:inset-0" v-if="menuopen">
                <div class="side-menu-box relative">
                    <div class="close absolute right-5 top-5"><mdicon name="close" class="text-gray-700 dark:text-gray-200 cursor-pointer" size="25" @click="menuopen = false"/></div>
                    <div class="logo-menu border-b border-gray-200 dark:border-gray-800 px-8 py-6 lg:py-8">
                         <picture>
                            <img v-if="dark" id="logo" class="logo-image" :src="LogoWhite" alt="Bank Padma">
                            <img v-if="!dark" id="logo" class="logo-image" :src="Logo" alt="Bank Padma">
                        </picture>
                    </div>
                    <div>
                        <div class="block lg:hidden px-8 class border-b dark:border-gray-800 border-gray-200">
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/tabungan" @click="menuopen = !menuopen">Tabungan</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/deposito" @click="menuopen = !menuopen">Deposito</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/kredit" @click="menuopen = !menuopen">Kredit</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/layanan" @click="menuopen = !menuopen">Layanan</router-link></div>
                        </div>
                        <div class="menu-group px-8 border-b dark:border-gray-800 border-gray-300 lg:border-0">
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/sejarah" @click="menuopen = !menuopen">Sejarah Perusahaan</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/visi-dan-misi" @click="menuopen = !menuopen">Visi & Misi Perusahaan</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/manajemen" @click="menuopen = !menuopen">Manajemen</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/struktur-organisasi" @click="menuopen = !menuopen">Struktur Organisasi</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/penghargaan" @click="menuopen = !menuopen">Penghargaan</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/laporan-keuangan" @click="menuopen = !menuopen">Laporan Keuangan</router-link></div>
                                                        <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/setoran-tabungan" @click="menuopen = !menuopen">Setoran Tabungan</router-link></div>
                                                        <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/kritik-dan-saran" @click="menuopen = !menuopen">Kritik dan Saran</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/brosur" @click="menuopen = !menuopen">Brosur</router-link></div>

                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/kredit/formulir-kredit" @click="menuopen = !menuopen">Pengajuan Kredit</router-link></div>
                        </div>

                        <div class="block lg:hidden px-8 class">
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/lokasi-kantor" @click="menuopen = !menuopen">Lokasi</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/suku-bunga" @click="menuopen = !menuopen">Suku Bunga</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/karir" @click="menuopen = !menuopen">Karir</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/blog" @click="menuopen = !menuopen">Blog</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/frequently-asked-questions" @click="menuopen = !menuopen">FAQ</router-link></div>
                            <div class="menu-item text-sm lg:text-base dark:text-gray-200 text-primary font-semibold lg:font-bold my-4 md:my-6 lg:my-7 uppercase"><router-link to="/asset" @click="menuopen = !menuopen">Promo Aset</router-link></div>
                        </div>
                    </div>
                   
                </div>
            </div>
         </transition>
    </div>
</template>
<script>
import store from '@/store';
import logo from '../assets/logo-padma.png';
import logowhite from '../assets/logo-padma-white.png';

export default {
    name: 'ClientFontNav',
    props: ['dark'],
    data() {
        return {
            menuopen:false,
            search:false,
            Logo: logo,
            LogoWhite: logowhite,
            formsearch:''
        };
    },

    mounted() {
        this.sticky()
    },

    methods: {
        sticky(){
            window.onscroll = function() {myFunction()};
            var header = document.getElementById("b-header");
            var sticky = header.offsetTop;
            function myFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
                document.getElementById("b-header").style.height = "5rem";                        
                if (window.innerWidth < 1224) {
                    document.getElementById("logo").style.width = "150px";
                    
                }else{
                    document.getElementById("logo").style.width = "200px";
                }
            } else {
                    header.classList.remove("sticky");                         
                    if (window.innerWidth < 1224) {
                        document.getElementById("b-header").style.height = "5rem";
                        document.getElementById("logo").style.width = "150px";
                    }else{
                        document.getElementById("b-header").style.height = "7rem";
                        document.getElementById("logo").style.width = "250px";
                    }
                }
            }
        },

        changedark(){
            this.$emit('darkmode',true)
        },
        changesearch(){
            if(this.search == true){
                this.search = false
                window.scrollTo(0, 0);
            } else {
                this.search = true;
                window.scrollTo(0, 0);
            }
        },
        cari(){
            this.$router.push({ name: 'Search', params: { search: this.formsearch } })
            this.search = false
        }
    },
    components:{
        
    },
    computed:{
        company(){
            return store.state.company
        },
        general(){
             return store.state.general
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/main.scss";
.menu-item .router-link-active.router-link-exact-active{
    color: $secondary !important;
}
.sticky {
    position:fixed !important;
    top:0;
    width:100%;
    transition: all .4s ease-in-out;
    z-index: 39;
}
.logo{
    img{
        width: 100%;
    }
}

.logo-menu{
    img{
         width: 150px !important;
    }   
}

.side-menu{
    top: 0;
    left: 0;
}

#logo{
    transition: all .4s ease-in-out;
}
.slide-fade-enter-active {
                transition: all 0.5s ease-in-out;
            }
            .slide-fade-leave-active {
                transition: all 0.5s;
            }
            .slide-fade-enter-from,
            .slide-fade-leave-to {
                transform: translateX(-400px);
                opacity: 0;
            }


            .fade-enter-active,
            .fade-leave-active {
                transition: all .5s ease-in-out;
            }

            .fade-enter-from,
            .fade-leave-to {
                opacity: 0;
            }

@media screen and (min-width:768px) {
    .logo{
        img{
            width: 250px;
        }
    }

    .logo-menu{
        img{
            width: 200px !important;
        }   
    }


     

        @media screen and (min-width:1224px) {
            .slide-fade-enter-active {
                transition: all .4s ease-in-out;
                transition-delay: .1s;
            }
            .slide-fade-leave-active {
                    transition: all .4s ease-in-out;
            }
            .slide-fade-enter-from,
            .slide-fade-leave-to {
                transform: translateX(400px);
                opacity: 0;
            }

            .side-menu{
                left: unset;
                right: 0 !important;
            }
            
        }

}

</style>