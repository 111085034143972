<template>
<div class="container-y pb-8">
    <v-loading v-if="isloading"></v-loading>
    <div class="border border-gray-200 dark:bg-gray-700 dark:border-0 rounded-md w-full px-5 md:px-14 py-5 md:py-8 shadow-md mb-4 md:mb-0">
        <form @submit.prevent="submitForm">
            <div class="text-lg md:text-3xl font-bold text-primary uppercase text-center mb-8 dark:text-gray-200">Formulir Pembukaan Deposito</div>
            <div class="bg-primary dark:bg-gray-800 text-white py-3 px-3 text-sm font-medium rounded-sm mb-5">Data Pribadi Pemohon</div>
            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Nama Lengkap</div>
                <div v-if="error.nama" class="text-xs text-danger font-medium">{{error.nama[0]}}</div>
                <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.nama" @change="form.nama = $event.target.value">
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Nomor KTP</div>
                <div v-if="error.ktp" class="text-xs text-danger font-medium">{{error.ktp[0]}}</div>
                <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.ktp" @change="form.ktp = $event.target.value">
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Alamat Lengkap</div>
                <div v-if="error.alamat" class="text-xs text-danger font-medium">{{error.alamat[0]}}</div>
                <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.alamat" @change="form.alamat = $event.target.value">
            </div>

            <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Kota</div>
                    <div v-if="error.kota" class="text-xs text-danger font-medium">{{error.kota[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-1 focus:outline-none placeholder:text-gray-500" :value="form.kota" @change="form.kota = $event.target.value">
                        <option value="">Pilih</option>
                        <option v-for="c in city" :key="c" :value="c.city">{{c.city}}</option>
                    </select>
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Status Perkawinan</div>
                    <div v-if="error.statuskawin" class="text-xs text-danger font-medium">{{error.statuskawin[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-1 focus:outline-none placeholder:text-gray-500" :value="form.statuskawin" @change="form.statuskawin = $event.target.value">
                        <option value="">Pilih</option>
                        <option value="Menikah">Menikah</option>
                        <option value="Lajang">Lajang</option>
                        <option value="Cerai">Cerai</option>
                    </select>
                </div>
            </div>

            <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">No.Handphone</div>
                    <div v-if="error.handphone" class="text-xs text-danger font-medium">{{error.handphone[0]}}</div>
                    <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.handphone" @change="form.handphone = $event.target.value">
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Email</div>
                    <div v-if="error.email" class="text-xs text-danger font-medium">{{error.email[0]}}</div>
                    <input type="email" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.email" @change="form.email = $event.target.value">
                </div>
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Nama Gadis Ibu Kandung</div>
                <div v-if="error.namaibu" class="text-xs text-danger font-medium">{{error.namaibu[0]}}</div>
                <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.namaibu" @change="form.namaibu = $event.target.value">
            </div>

            <div class="bg-primary dark:bg-gray-800 text-white py-3 px-3 text-sm font-medium rounded-sm mb-5">Data Pekerjaan & Penghasilan</div>

            <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Pekerjaan</div>
                    <div v-if="error.pekerjaan" class="text-xs text-danger font-medium">{{error.pekerjaan[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-1 focus:outline-none placeholder:text-gray-500" :value="form.pekerjaan" @change="form.pekerjaan = $event.target.value">
                        <option v-for="j in jobs" :key="j" :value="j.job">{{j.job}}</option>
                    </select>
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Tempat Bekerja</div>
                    <div v-if="error.tempatkerja" class="text-xs text-danger font-medium">{{error.tempatkerja[0]}}</div>
                    <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.tempatkerja" @change="form.tempatkerja = $event.target.value">
                </div>
            </div>

            <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Sumber Penghasilan</div>
                    <div v-if="error.sumberpenghasilan" class="text-xs text-danger font-medium">{{error.sumberpenghasilan[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-1 focus:outline-none placeholder:text-gray-500" :value="form.sumberpenghasilan" @change="form.sumberpenghasilan = $event.target.value">
                        <option v-for="s in sumberpenghasilan" :key="s.id" :value="s.sumber">{{s.sumber}}</option>
                    </select>
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Penghasilan Pertahun</div>
                    <div v-if="error.penghasilantahun" class="text-xs text-danger font-medium">{{error.penghasilantahun[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-1 focus:outline-none placeholder:text-gray-500" :value="form.penghasilan" @change="form.penghasilan = $event.target.value">
                        <option v-for="p in penghasilantahun" :key="p.id" :value="p.income">{{p.income}}</option>
                    </select>
                </div>
            </div>

            <div class="bg-primary dark:bg-gray-800 text-white py-3 px-3 text-sm font-medium rounded-sm mb-5">Data Deposito</div>
            <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Jenis Deposito</div>
                    <div v-if="error.jenisdeposito" class="text-xs text-danger font-medium">{{error.jenisdeposito[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-1 focus:outline-none placeholder:text-gray-500" :value="form.jenisdeposito" @change="form.jenisdeposito = $event.target.value">
                        <option v-for="item in items" :key="item" :value="item.title">{{item.title}}</option>
                    </select>
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Nominal Penempatan</div>
                    <div v-if="error.nominal" class="text-xs text-danger font-medium">{{error.nominal[0]}}</div>
                    <VueNumberFormat class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model:value="form.nominal" :options="{ precision: 0, prefix: 'Rp. ', decimal: '', thousand: ',', acceptNegative: false, isInteger: false  }"></VueNumberFormat>
                </div>
            </div>

            <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Jangka Waktu</div>
                    <div v-if="error.jangkawaktu" class="text-xs text-danger font-medium">{{error.jangkawaktu[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-1 focus:outline-none placeholder:text-gray-500" :value="form.jangkawaktu" @change="form.jangkawaktu = $event.target.value">
                        <option :value="d.duration" v-for="d in dd" :key="d.id">{{d.duration}}</option>
                    </select>
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Perpanjangan</div>
                    <div v-if="error.perpanjangan" class="text-xs text-danger font-medium">{{error.perpanjangan[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-1 focus:outline-none placeholder:text-gray-500" :value="form.perpanjangan" @change="form.perpanjangan = $event.target.value">
                        <option value="Tidak di Perpanjang">Tidak di Perpanjang</option>
                        <option value="ARO">ARO</option>
                        <option value="ARO + Bunga">ARO + Bunga</option>
                    </select>
                </div>
            </div>

            <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Tujuan Pembukaan Deposito</div>
                    <div v-if="error.tujuan" class="text-xs text-danger font-medium">{{error.tujuan[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-1 focus:outline-none placeholder:text-gray-500" :value="form.tujuan" @change="form.tujuan = $event.target.value">
                        <option value="Investasi">Investasi</option>
                        <option value="Transaksi">Transaksi</option>
                        <option value="Keperluan Pribadi">Keperluan Pribadi</option>
                        <option value="Lainnya">Lainnya</option>
                    </select>
                </div>
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Nama Ahli Waris</div>
                    <div v-if="error.ahliwaris" class="text-xs text-danger font-medium">{{error.ahliwaris[0]}}</div>
                    <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.ahliwaris" @change="form.ahliwaris = $event.target.value">
                </div>
            </div>

            <div class="bg-primary dark:bg-gray-800 text-white py-3 px-3 text-sm font-medium rounded-sm mb-5">Kelengkapan Data</div>

            <div class="form-group mb-5">
                <div class="dark:text-gray-200 text-sm font-semibold inline-block text-gray-700 relative after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1">Foto KTP (Max 2MB)</div>
                <input type="File" class="block" @change="selectktp($event)">
            </div>
            <div class="form-group mb-5">
                <div v-html="termform.baseterm" class="term text-sm font-normal text-gray-500 leading-relaxed mb-2">

                </div>
                <div class="form-check flex justify-start items-start">
                    <input type="checkbox" class="form-check-input relative mr-1" name="setuju" v-model="form.term" required>
                    <label class="form-check-label text-sm font-normal text-gray-500 dark:text-gray-200 flex" for="setuju">
                        <span class="text-[#ff0000] ml-1 mr-1">*</span>
                        <div v-html="termform.subterm"></div>
                    </label>
                </div>
            </div>

            <div class="form-group mb-5">
                <div class="dark:text-gray-200 text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-2">Tanda Tangan</div>
                <div class="border-gray-300 dark:bg-gray-200 dark:border-0 rounded-sm overflow-hidden border w-full md:w-3/4 lg:w-2/4 h-60 md:h-72 relative group">
                    <VueSignaturePad ref="signaturePad" />
                    <div class="absolute top-0 right-0 bg-gray-400 dark:border-0 text-white flex text-center justify-center items-center p-2 cursor-pointer lg:translate-y-[-50px] lg:group-hover:translate-y-0 duration-300" @click="clear">
                        <mdicon name="trash-can-outline" size="20" class="mr-1 cursor-pointer" />
                    </div>
                </div>
            </div>

            <div class="form-group mb-5">
                <div ref="recaptcha" class="g-recaptcha" :data-sitekey="siteKey"></div>
            </div>

            <div class="form-group mb-5 flex flex-col md:flex-row">
                <button type="submit" class="bg-primary text-white mb-2 text-xs font-semibold rounded-sm py-3 px-4 duration-300 mr-2 flex justify-center items-center">
                    <mdicon name="send" size="20" class="mr-1 cursor-pointer" :disabled="isloading" />{{ isloading ? 'Proses...' : 'Buka Deposito' }}</button>
                <button type="Reset" class="bg-secondary text-white mb-2 text-xs font-semibold rounded-sm py-3 px-4 duration-300 mr-2 flex justify-center items-center" @click="clear">
                    <mdicon name="restore" size="20" class="mr-1 cursor-pointer" /> Reset Form</button>
            </div>
        </form>
    </div>
    <div class="md:hidden">
        <Share></Share>
    </div>
</div>
</template>

<script>
import Share from '../../layouts/Share.vue';
import { masterSiteKey } from '@/main.js';
export default {

    name: 'ClientFormDeposito',

    data() {
        return {
            siteKey: masterSiteKey, // Ganti dengan kunci situs reCAPTCHA Anda
            recaptchaWidgetId: null,
            isloading: false,
            modal: false,
            items: [],
            form: {
                nama: '',
                ktp: '',
                alamat: '',
                kota: '',
                handphone: '',
                email: '',
                statuskawin: '',
                namaibu: '',
                pekerjaan: '',
                tempatkerja: '',
                sumberpenghasilan: '',
                penghasilan: '',
                jenisdeposito: '',
                nominal: 0,
                jangkawaktu: '',
                perpanjangan: '',
                tujuan: '',
                ahliwaris: '',
                term: false,
                ktpdiri: null,
                sign: ''
            },
            defaultform: {
                nama: '',
                ktp: '',
                alamat: '',
                kota: '',
                handphone: '',
                email: '',
                statuskawin: '',
                namaibu: '',
                pekerjaan: '',
                tempatkerja: '',
                sumberpenghasilan: '',
                penghasilan: '',
                jenisdeposito: '',
                nominal: 0,
                jangkawaktu: '',
                perpanjangan: '',
                tujuan: '',
                ahliwaris: '',
                term: false,
                ktpdiri: null,
                sign: '',
            },
            dd: [],
            city: [],
            jobs: [],
            sumberpenghasilan: [{
                    id: '1',
                    sumber: 'Gaji'
                },
                {
                    id: '2',
                    sumber: 'Usaha'
                },
                {
                    id: '3',
                    sumber: 'Orang Tua'
                },
                {
                    id: '4',
                    sumber: 'Lainnya'
                }
            ],
            penghasilantahun: [],
            termform: {

            },
            error: []

        };
    },

    mounted() {
        this.loadRecaptcha();
        this.getDeposit();
        this.getJob();
        this.getCity();
        this.getDD();
        this.getIncome();
        this.getterm();
    },

    methods: {
        loadRecaptcha() {
            if (window.grecaptcha) {
                try {
                    this.recaptchaWidgetId = grecaptcha.render(this.$refs.recaptcha, {
                        sitekey: this.siteKey,
                        callback: this.recaptchaCallback
                    });
                } catch (error) {
                    console.error('Error loading reCAPTCHA:', error);
                    this.$notify({
                        title: "Error",
                        type: "error",
                        text: "Failed to load reCAPTCHA. Please try again later."
                    });
                }
            } else {
                setTimeout(this.loadRecaptcha, 100);
            }
        },

        recaptchaCallback(response) {
            console.log('reCAPTCHA verified:', response);
        },
        submitForm() {
            if (this.recaptchaWidgetId === null) {
                this.$notify({
                    title: "Error",
                    type: "error",
                    text: "reCAPTCHA not loaded. Please reload the page and try again."
                });
                return;
            }

            const response = grecaptcha.getResponse(this.recaptchaWidgetId);
            if (!response) {
                this.$notify({
                    title: "Error",
                    type: "error",
                    text: "Please verify reCAPTCHA"
                });
                return;
            } else {
                this.isloading = true;
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

                let formData = new FormData();

                if (isEmpty) {
                    alert('Sign Harus Isi')
                } else {
                    formData.append('sign', data);
                }
                formData.append('nama', this.form.nama);
                formData.append('ktp', this.form.ktp);
                formData.append('alamat', this.form.alamat);
                formData.append('kota', this.form.kota);
                formData.append('handphone', this.form.handphone);
                formData.append('email', this.form.email);
                formData.append('statuskawin', this.form.statuskawin);
                formData.append('namaibu', this.form.namaibu);
                formData.append('pekerjaan', this.form.pekerjaan);
                formData.append('tempatkerja', this.form.tempatkerja);
                formData.append('sumberpenghasilan', this.form.sumberpenghasilan);
                formData.append('penghasilan', this.form.penghasilan);
                formData.append('jenisdeposito', this.form.jenisdeposito);
                formData.append('nominal', this.form.nominal);
                formData.append('jangkawaktu', this.form.jangkawaktu);
                formData.append('perpanjangan', this.form.perpanjangan);
                formData.append('tujuan', this.form.tujuan);
                formData.append('ahliwaris', this.form.ahliwaris);
                formData.append('term', this.form.term);
                formData.append('ktpdiri', this.form.ktpdiri);
                formData.append('formterm', JSON.stringify(this.termform));
                this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                this.axios.post('api/storefdepositos', formData).then(() => {
                    this.clear();
                    this.clearform();
                    this.$notify({
                        title: "Pembukaan Deposito Akan Di Proses",
                        type: "success",
                        text: "Petugas Kami Akan Segera Menghubungi Anda"
                    });
                }).catch(error => {
                    this.$notify({
                        title: "Gagal Proses Permohonan",
                        type: "error",
                        text: "Ada Data Yang Kurang Lengkap"
                    });
                    this.error = error.response.data
                }).finally(() => {
                    grecaptcha.reset(this.recaptchaWidgetId);
                    this.isloading = false;
                })

            }
        },
        getterm() {
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getterm/Deposit`).then((response) => {
                this.termform = JSON.parse(response.data.term)
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        },
        getIncome() {

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getincome`).then((response) => {
                this.penghasilantahun = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        },
        getCity() {

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getcity`).then((response) => {
                this.city = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        },
        getJob() {

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getjob`).then((response) => {
                this.jobs = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        },
        getDD() {

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getdepositduration`).then((response) => {
                this.dd = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        },
        clearform: function () {
            this.form = Object.assign({}, this.defaultForm);
        },
        selectktp(event) {
            this.form.ktpdiri = event.target.files[0];
        },
        clear() {
            this.$refs.signaturePad.clearSignature();
        },
        getDeposit() {

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getdeposit`).then((response) => {
                this.items = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        }
    },
    computed: {
        penghasilanbersih: {
            get: function () {
                let val = 0
                val = this.form.penghasilan - this.form.pengeluaran - this.form.angsuranBankLain;
                return val
            },
            set: function () {

            }
        },
    },
    components: {
        Share
    }
};
</script>

<style lang="scss" scoped>

</style>
