import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FrontLayout from '../layouts/front-layout.vue'
import AdminLayout from '../layouts/admin-layout.vue'
import SecureLS from "secure-ls" 
import Tabungan from '@/views/TabunganView.vue'
import SearchView from '@/views/SearchView.vue'
import FormTabungan from '@/views/Form-Tabungan.vue'
import DTabungan from '@/views/DTabungan.vue'
import Deposito from '@/views/DepositoView.vue'
import FDeposito from '@/views/Form-Deposito.vue'
import DDeposito from '@/views/DDeposito.vue'
import LayananView from '@/views/LayananView.vue'
import DLayanan from '@/views/DLayanan.vue'
import KreditView from '@/views/KreditView.vue'
import FLowongan from '@/views/Form-Lowongan.vue'
import FKredit from '@/views/Form-Kredit.vue'
import DKredit from '@/views/DKredit.vue'
import SukuBungaView from '@/views/SukuBungaView.vue'
import SetorTabungan from '@/views/SetorTabungan.vue'
import KritikSaran from '@/views/KritikSaran.vue'
import DBunga from '@/views/DBunga.vue'
import KarirView from '@/views/KarirView.vue'
import LokasiView from '@/views/LokasiView.vue'
import faqView from '@/views/faqView.vue'
import AssetView from '@/views/AssetView.vue'
import BlogView from '@/views/BlogView.vue'
import blogCatView from '@/views/blogCatView.vue'
import blogTagView from '@/views/blogTagView.vue'
import DBlog from '@/views/DBlog.vue'
import SejarahView from '@/views/SejarahView.vue'
import VisiMisiView from '@/views/VisiMisiView.vue'
import ManajemenView from '@/views/ManajemenView.vue'
import DetailManajemenView from '@/views/DetailManajemenView.vue'
import StrukturView from '@/views/StrukturView.vue'
import PenghargaanView from '@/views/PenghargaanView.vue'
import LaporanView from '@/views/LaporanView.vue'
import BrosurView from '@/views/BrosurView.vue'
import Dashboard from '@/views/admin/dashboard.vue'
import userView from '@/views/admin/userView.vue'
import accessView from '@/views/admin/accessView.vue'
import productView from '@/views/admin/productView.vue'
import blogView from '@/views/admin/blogView.vue'
import rateView from '@/views/admin/rateView.vue'
import careerView from '@/views/admin/careerView.vue'
import faqView1 from '@/views/admin/faqView.vue'
import testimonialView from '@/views/admin/testimonialView.vue'
import assetView from '@/views/admin/assetView.vue'
import companyView from '@/views/admin/companyView.vue'
import criticView from '@/views/admin/criticView.vue'
import visitorsView from '@/views/admin/visitorsView.vue'
import reportView from '@/views/admin/reportView.vue'
import vacancyView from '@/views/admin/vacancyView.vue'
import heroView from '@/views/admin/heroView.vue'
import submissionView from '@/views/admin/submissionView.vue'
import transferView from '@/views/admin/transferView.vue'
import parameterView from '@/views/admin/parameterView.vue'
import LoginView from '@/views/admin/LoginView.vue'
import ForbiddenView from '@/views/admin/ForbiddenView.vue'
import NotFound from '@/views/NotFound.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/search/:search',
    name: 'Search',
    props: true,
    component: SearchView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/tabungan/formulir-tabungan',
    name: 'FTabungan',
    component: FormTabungan,
    meta: { layout: FrontLayout }
  },
  {
    path: '/tabungan/:name',
    name: 'DTabungan',
    component:DTabungan,
    meta: { layout: FrontLayout },
    props:true,
  },
  {
    path: '/deposito',
    name: 'Deposito',
    component:Deposito,
    meta: { layout: FrontLayout }
  },
  {
    path: '/tabungan',
    name: 'Tabungan',
    component:Tabungan,
    meta: { layout: FrontLayout }
  },
  {
    path: '/deposito/formulir-deposito',
    name: 'FDeposito',
    component: FDeposito,
    meta: { layout: FrontLayout }
  },
  {
    path: '/deposito/:name',
    name: 'DDeposito',
    component: DDeposito,
    meta: { layout: FrontLayout },
    props:true,
  },
  {
    path: '/layanan',
    name: 'Layanan',
    component: LayananView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/layanan/:name',
    name: 'DLayanan',
    component: DLayanan,
    meta: { layout: FrontLayout },
    props:true,
  },
  {
    path: '/kredit',
    name: 'Kredit',
    component: KreditView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/karir/formulir-lamaran-kerja',
    name: 'FormLamaran',
    component: FLowongan,
    meta: { layout: FrontLayout }
  },
  {
    path: '/kredit/formulir-kredit',
    name: 'FKredit',
    component: FKredit,
    meta: { layout: FrontLayout }
  },
  {
    path: '/kredit/:name',
    name: 'DKredit',
    component: DKredit,
    meta: { layout: FrontLayout },
    props:true,
  },
  {
    path: '/suku-bunga',
    name: 'SukuBunga',
    component: SukuBungaView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/setoran-tabungan',
    name: 'SetorTabungan',
    component: SetorTabungan,
    meta: { layout: FrontLayout }
  },
  {
    path: '/kritik-dan-saran',
    name: 'KritikSaran',
    component: KritikSaran,
    meta: { layout: FrontLayout }
  },
  {
    path: '/suku-bunga/:name',
    name: 'DBunga',
    component: DBunga,
    meta: { layout: FrontLayout },
    props:true,
  },
  {
    path: '/karir',
    name: 'Karir',
    component: KarirView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/lokasi-kantor',
    name: 'Lokasi',
    component: LokasiView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/frequently-asked-questions',
    name: 'FAQ',
    component: faqView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/asset',
    name: 'Asset',
    component: AssetView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/blog/kategori/:category',
    name: 'CBlog',
    props: true,
    component: blogCatView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/blog/tags/:tag',
    name: 'TBlog',
    props: true,
    component: blogTagView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/blog/:blog',
    name: 'DBlog',
    props: true,
    component: DBlog,
    meta: { layout: FrontLayout }
  },

  {
    path: '/sejarah',
    name: 'Sejarah',
    component: SejarahView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/visi-dan-misi',
    name: 'Visi',
    component: VisiMisiView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/manajemen',
    name: 'Manajemen',
    component: ManajemenView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/manajemen/:permalink',
    props:true,
    name: 'DManajemen',
    component: DetailManajemenView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/struktur-organisasi',
    name: 'Struktur',
    component: StrukturView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/penghargaan',
    name: 'Penghargaan',
    component: PenghargaanView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/laporan-keuangan',
    name: 'Laporan',
    component: LaporanView,
    meta: { layout: FrontLayout }
  },
  {
    path: '/brosur',
    name: 'Brosur',
    component: BrosurView,
    meta: { layout: FrontLayout }
  },

  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      layout: AdminLayout,
      pagename: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/admin/users',
    name: 'Users',
    component: userView,
    meta: {
      layout: AdminLayout,
      pagename: 'Users',
      requiresAuth: true
    }
  },
  {
    path: '/admin/access-controls',
    name: 'Access',
    component: accessView,
    meta: {
      layout: AdminLayout,
      pagename: 'Access Control',
      requiresAuth: true
    }
  },
  {
    path: '/admin/products',
    name: 'Products',
    component: productView,
    meta: {
      layout: AdminLayout,
      pagename: 'Products',
      requiresAuth: true
    }
  },
  {
    path: '/admin/blogs',
    name: 'Blogs',
    component: blogView,
    meta: {
      layout: AdminLayout,
      pagename: 'Blogs',
      requiresAuth: true
    }
  },
  {
    path: '/admin/rates',
    name: 'Rates',
    component: rateView,
    meta: {
      layout: AdminLayout,
      pagename: 'Rates',
      requiresAuth: true
    }
  },
  {
    path: '/admin/careers',
    name: 'Careers',
    component: careerView,
    meta: {
      layout: AdminLayout,
      pagename: 'Careers',
      requiresAuth: true
    }
  },
  {
    path: '/admin/frequently-asked-questions',
    name: 'FAQS',
    component: faqView1,
    meta: {
      layout: AdminLayout,
      pagename: 'Frequently Asked Questions',
      requiresAuth: true
    }
  },
  {
    path: '/admin/testimonials',
    name: 'Testimonial',
    component: testimonialView,
    meta: {
      layout: AdminLayout,
      pagename: 'Testimonials',
      requiresAuth: true
    }
  },
  {
    path: '/admin/assets',
    name: 'Assets',
    component: assetView,
    meta: {
      layout: AdminLayout,
      pagename: 'Assets',
      requiresAuth: true
    }
  },
  {
    path: '/admin/companies',
    name: 'Companies',
    component: companyView,
    meta: {
      layout: AdminLayout,
      pagename: 'Companies',
      requiresAuth: true
    }
  },
  {
    path: '/admin/critics',
    name: 'Critics',
    component: criticView,
    meta: {
      layout: AdminLayout,
      pagename: 'Critics',
      requiresAuth: true
    }
  },
  {
    path: '/admin/visitors',
    name: 'Visitors',
    component: visitorsView,
    meta: {
      layout: AdminLayout,
      pagename: 'Visitors',
      requiresAuth: true
    }
  },
  {
    path: '/admin/reports',
    name: 'Reports',
    component: reportView,
    meta: {
      layout: AdminLayout,
      pagename: 'Reports',
      requiresAuth: true
    }
  },
  {
    path: '/admin/vacancies',
    name: 'Vacancies',
    component: vacancyView,
    meta: {
      layout: AdminLayout,
      pagename: 'Vacancies',
      requiresAuth: true
    }
  },
  {
    path: '/admin/heroes',
    name: 'Hero',
    component: heroView,
    meta: {
      layout: AdminLayout,
      pagename: 'Heroes',
      requiresAuth: true
    }
  },
  {
    path: '/admin/submissions',
    name: 'Submission',
    component: submissionView,
    meta: {
      layout: AdminLayout,
      pagename: 'Submissions',
      requiresAuth: true
    }
  },
  {
    path: '/admin/transfers',
    name: 'Transfers',
    component: transferView,
    meta: {
      layout: AdminLayout,
      pagename: 'Transfers',
      requiresAuth: true
    }
  },
  {
    path: '/admin/parameters',
    name: 'Parameters',
    component: parameterView,
    meta: {
      layout: AdminLayout,
      pagename: 'Parameters',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      pagename: 'Login'
    }
  },

  {
    path: '/forbidden',
    name: 'Forbidden',
    component: ForbiddenView,
    meta: {
      pagename: 'Login'
    }
  },

  {
    path: "/:pathMatch(.*)*",
    name: 'NotFound',
    component: NotFound
  },

]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

function loggedIn() {
  return localStorage.getItem('session')
}


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if(loggedIn()){
      const  ls = new SecureLS({isCompression: false})  
      const permissions = JSON.parse(ls.get('session').data.permissions)      
      for(let i = 0 ; i < permissions.length ; i++){
          if(to.path == permissions[i].route && permissions[i].read == true){
              next()
          } else if(to.path == permissions[i].route && permissions[i].read == false){
            next({path : '/forbidden'})
          }
      }      
    }else{
      next({path : '/login'})
    }
	}else if(to.path == '/admin'){
    next({path : '/admin/dashboard'})
  }else  next()

   
} 
)

export default router
