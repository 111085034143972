<template>
    <div>
        <v-loading v-if="isLoading"></v-loading>
       <DataTable :datatable="items" :fixed="false" :header="headers" @page-changed="pageChange($event)">
           <template v-slot:table-slot>
               <div class="w-full grid gap-5 grid-cols-[1fr_auto]">
                   <form @submit.prevent action="" class="w-full">
                       <div class="flex border-b-2 bg-gray-100 border-gray-300 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-800 focus:outline-none py-1 mb-4 w-full text-gray-700 font-medium">
                           <input type="text" class="focus:outline-none bg-transparent w-full" placeholder="Search..." v-model="search">
                           <mdicon name="magnify" size="28" class="mr-1 text-gray-700 dark:text-gray-100"/>
                       </div>
                    </form>

                    <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-primary inline-block relative h-8 rounded shadow w-16 text-center text-gray-100" @click="addModal" v-if="isCreatePermitted">Add</button>
               </div>
           </template>

           <template v-slot:action="item">
                <div class="flex">
                   <button class="flex justify-center items-center bg-primary mr-2 px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="editModal(item)" v-if="isUpdatePermitted"><mdicon name="pencil" class="text-gray-100 mr-[2px]" size="15"/><span>Edit</span></button>
                   <button class="flex justify-center items-center bg-secondary px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="deleteModal(item)" v-if="isDeletePermitted"><mdicon name="trash-can-outline" class="text-gray-100 mr-[2px]" size="15"/><span>Delete</span></button>
               </div>
           </template>
       </DataTable>
       <LargeFormBaseVue v-if="modal" @closeModal="modal = !modal">
            <template v-slot:formName>
               {{title}}
            </template>
            <template v-slot:main>
               <div class="grid gap-5 grid-cols-[8fr_4fr]">
                   <div class="left">
                        <div class="form-group mb-5">
                           <label for="title" class="text-sm font-bold text-gray-500">Type</label>
                           <select name="" id="" class="bg-gray-100 w-full border-[1px] border-gray-400 rounded-sm mt-2 dark:border-gray-800 dark:text-gray-100 focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.type">
                                <option value="">Choose</option>
                                <option value="Home">Home</option>
                                <option value="Tabungan">Tabungan</option>
                                <option value="Deposito">Deposito</option>
                                <option value="Kredit">Kredit</option>
                                <option value="Layanan Kami">Layanan Kami</option>
                                <option value="Suku Bunga">Suku Bunga</option>
                                <option value="Karir">Karir</option>
                                <option value="Frequently Ask Question (FAQ)">Frequently Ask Question (FAQ)</option>
                                <option value="General">General</option>
                                <option value="Penghargaan">Penghargaan</option>
                                <option value="Laporan Keuangan">Laporan Keuangan</option>
                                <option value="Asset">Asset</option>
                                <option value="Faq">Faq</option>
                                <option value="Digital Brosur">Digital Brosur</option>
                                <option value="Setoran Tabungan">Setoran Tabungan</option>
                                <option value="Kritik & Saran">Kritik & Saran</option>
                                <option value="Blog">Blog</option>
                                <option value="Pencarian">Pencarian</option>
                                <option value="Formulir Kredit">Formulir Kredit</option>
                                <option value="Formulir Deposito">Formulir Deposito</option>
                                <option value="Formulir Tabungan">Formulir Tabungan</option>
                                <option value="Formulir Lamaran Kerja">Formulir Lamaran Kerja</option>
                           </select>
                        </div>   
                        <div class="mr-3 mb-2">
                            <label for="title" class="text-sm font-bold text-gray-500">Image</label>
                            <button class="border-gray-400 border-[1px] items-center flex text-gray-500 px-3 py-2 rounded-sm text-xs font-medium" onclick="document.getElementById('fileImage').click()"><mdicon name="paperclip" size="15" class="text-gray-500 " /> <span>{{ form.image == null ? 'Upload Image' : 'Image Uploaded' }}</span></button>
                            <input type='file' id="fileImage" style="display:none" @change="getImage"> 
                        </div>
                        <div class="form-group mb-5">
                           <label for="title" class="text-sm font-bold text-gray-500">Description</label>
                           <textarea name="" id="" cols="30" rows="10"  class="bg-gray-100 w-full border-[1px] border-gray-400 rounded-sm mt-2 dark:border-gray-800 dark:text-gray-100 focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.description"></textarea>
                        </div>
                   </div>
                   <div class="right">                         
                        <div class="text-sm font-bold text-gray-500 mb-2">Keywords</div>
                        <div class="form-group mb-5">
                            <taginputVue :tags="form.keyword" @addTags="addTag" @removeTags="removeTag"></taginputVue>
                        </div>  
                        <div class="form-group mb-5 flex items-center">
                            <div class="flex mr-5 items-center">
                                <input type="checkbox" id="html" name="fav_language" v-model="form.showtitle">
                                <label for="html" class="text-sm font-medium text-gray-500 ml-2">Display Title</label>
                            </div>
                            <div class="flex items-center">
                                <input type="checkbox" id="css" name="fav_language" v-model="form.showdesc">
                                <label for="css" class="text-sm font-medium text-gray-500 ml-2">Display Description</label>
                            </div>
                        </div> 
                        
                        <div class="flex mt-10">
                            <LButton class="text-white bg-secondary mr-2" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="save">
                                {{ isloading ? 'Loading...' : 'Save' }}
                            </LButton>   
                           <LButton class="bg-gray-300 text-gray-800">
                            Cancel
                            </LButton>
                            
                        </div>
                   </div>
               </div>
            </template>
       </LargeFormBaseVue>
       
       <Transition name="fade">
            <Confirm v-if="deletemodal" @closeconfirm="deletemodal = false">
                <template v-slot:header>
                    Confirm
                </template>
                <template v-slot:main>
                    Are You Sure You Want To Delete This Data ?
                </template>
                <template v-slot:footer>
                    <div class="flex justify-end items-center">
                        <LButton class="bg-gray-300 text-gray-800 mr-2" @click="deletemodal = false">
                            Cancel
                        </LButton>
                        <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="deleteData">
                            {{ isloading ? 'Loading...' : 'Confirm' }}
                        </LButton>                            
                    </div>
                </template>
            </Confirm>  
        </Transition>
    </div> 
</template>

<script>
import LButton from '@/layouts/button.vue'
import Confirm from '@/layouts/confirm.vue'
import DataTable from '@/layouts/DataTable.vue'
import LargeFormBaseVue from '@/layouts/LargeFormBase.vue'
import taginputVue from '@/layouts/taginput.vue'

export default {
    name: 'PadmawebBannerTable',

    data() {
        return {
            isLoading:false,
            deletemodal: false,
            editIndex : -1,
            deleteID: null,
            form : {
                id: '',
                type:'',
                image: null,
                description : '',
                keyword : [],
                showtitle:false,
                showdesc:false,               
            },
           defaultForm : {
                id: '',
                type:'',
                image: null,
                description : '',
                keyword : [],
                showtitle:false,
                showdesc:false,      
            },
            headers:[],
            items:[],
            currentPage : 1,
            modal:false,
            roles: [],
            search: ''
        };
    },

    watch:{
        search:function(){
            if(this.search.length > 0){
                this.searchData(this.search)
            } else {
                this.getData()
            }
            
        },
        modal(val) {
			val || this.clearform();
		},
    },

    mounted() {
        this.getData()
        this.getheader()
    },

    methods: {
        addTag(val) {			
           this.form.keyword.push(val)
        },
		removeTag(index){
			 this.form.keyword.splice(index, 1)
		},
        getheader(){
            if(this.isDeletePermitted == true || this.isUpdatePermitted == true){
                this.headers = [
                    {
                        Text : 'ID',
                        value : 'id',
                        type: 'string'
                    },
                    {
                        Text: 'Type',
                        value: 'type',
                        type: 'string'
                    },
                    {
                        Text: 'Image',
                        value: 'imagepath',
                        type: 'images'
                    }
                    ,
                    {
                        Text: 'Keyword',
                        value: 'keyword',
                        type: 'string'
                    },
                    {
                        Text: 'Actions',
                        action: true,
                    }
                ]
            }else{
                this.headers = [
                    {
                        Text : 'ID',
                        value : 'id',
                        type: 'string'
                    },
                    {
                        Text: 'Type',
                        value: 'type',
                        type: 'string'
                    },
                    {
                        Text: 'Image',
                        value: 'imagepath',
                        type: 'images'
                    }
                    ,
                    {
                        Text: 'Keyword',
                        value: 'keyword',
                        type: 'string'
                    }
                ]
            }
        },
        addModal(){
            this.editIndex = -1
            this.modal = !this.modal
        },
        deleteModal(val){
            this.deleteID = JSON.parse(JSON.stringify(val)).item.id
            this.deletemodal = true
        },
        editModal(val){
            this.editIndex = 1
            let obj = JSON.parse(JSON.stringify(val)).item

            var str = obj.keyword;
			const arr = str.split(',');

            this.form.id = obj.id
            this.form.title = obj.title
            this.form.type = obj.type
            this.form.description = obj.description
            this.form.keyword = arr
            if(obj.showtitle == "true"){
				this.form.showtitle = true;
			}else{
				this.form.showtitle = false;
			}
			if(obj.showdesc == "true"){
				this.form.showdesc = true;
			}else{
				this.form.showdesc = false;
			}            

            this.modal = true
        },
        getImage(event){
           this.form.image = event.target.files[0];
        },
        changePage(e){
            this.currentPage = e;
            this.getData()
        },

        getData(){
            this.isLoading = true;

           this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/banner?page=${this.currentPage}`).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                this.isLoading = false;

            })
        },
        searchData(val){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/banner/search/'+val).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        pageChange: function(e){
           this.currentPage = e;
           this.getData()
        },

        clearform: function() {
            this.modal = false
            this.$nextTick(() => {
                this.form = Object.assign({}, this.defaultForm);
                this.editIndex = -1;
            });
        }
           
        ,
        save(){
            this.isLoading = true;

            if(this.editIndex > -1){
                let formData = new FormData();
                if( this.form.image !== null){
					formData.append('image', this.form.image);
				}
                formData.append('id', this.form.id);				
                formData.append('type', this.form.type);		
				formData.append('description', this.form.description);
				formData.append('keyword', this.form.keyword);
				formData.append('showtitle', this.form.showtitle);
				formData.append('showdesc', this.form.showdesc);

                    this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                    this.axios.post('/api/banner/update',formData).then(() => {
                    this.getData();
                    this.clearform()
                    this.$notify({
                        title: "Notification",
                        type: "success",
                        text: "Data Updated Successfully"
                    });
                }).catch(error => {                
                    console.log(error)
                }).finally(() => {
                    this.isLoading = false;

                })
            }else{
                
                let formData = new FormData();	
                formData.append('type', this.form.type);	
				formData.append('description', this.form.description);
				formData.append('keyword', this.form.keyword);
                formData.append('image', this.form.image);		
                formData.append('showtitle', this.form.showtitle);
				formData.append('showdesc', this.form.showdesc);
                    this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                    this.axios.post('/api/banner',formData).then(() => {
                    this.getData();
                    this.clearform()
                    this.$notify({
                        title: "Notification",
                        type: "success",
                        text: "Data Created Successfully"
                    });
                }).catch(error => {                
                    console.log(error)
                }).finally(() => {
                    this.isLoading = false;

                })
            }
        },
        deleteData(){
            this.isLoading = true;

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.delete('api/banner/'+this.deleteID).then(() => {
                this.getData()
                this.deleteID = null
                this.deletemodal = false
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                this.isLoading = false;

                
            })
        }
    },
    components:{
        DataTable,
        Confirm,
        LButton,
        LargeFormBaseVue,
        taginputVue
    },
    computed:{
        title(){
            if(this.editIndex > -1){
                return 'Edit Item'
            }else{
                return 'New Item'
            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>