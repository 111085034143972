<template>
<div class="container-y pb-5">
    <div>
        <div class="grid grid-cols-1 md:grid-cols-[3fr_2fr] gap-5 mb-5">
            <div class="left rounded-sm">
                <form @submit.prevent="submitForm" class="shadow py-5 md:py-8 md:px-7 px-5 border border-gray-300 dark:bg-gray-900 dark:border-gray-900">
                    <div class="bg-primary dark:bg-gray-900 text-white py-3 text-center px-3 text-xl font-bold rounded-sm mb-5">Formulir Kritik & Saran</div>
                    <div class="form-group mb-5">
                        <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Nama</div>
                        <div v-if="error.name" class="text-xs text-danger font-medium">{{error.name[0]}}</div>
                        <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.name">
                    </div>
                    <div class="form-group mb-5">
                        <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Alamat Lengkap</div>
                        <div v-if="error.address" class="text-xs text-danger font-medium">{{error.address[0]}}</div>
                        <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.address">
                    </div>
                    <div class="form-group mb-5">
                        <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Kota</div>
                        <div v-if="error.city" class="text-xs text-danger font-medium">{{error.city[0]}}</div>
                        <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.city">
                    </div>
                    <div class="form-group mb-5">
                        <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Email</div>
                        <div v-if="error.email" class="text-xs text-danger font-medium">{{error.email[0]}}</div>
                        <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.email">
                    </div>
                    <div class="form-group mb-5">
                        <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">No Telp / Hp</div>
                        <div v-if="error.phone" class="text-xs text-danger font-medium">{{error.phone[0]}}</div>
                        <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.phone">
                    </div>
                    <div class="form-group mb-5">
                        <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Kritik & Saran</div>
                        <div v-if="error.suggestion" class="text-xs text-danger font-medium">{{error.suggestion[0]}}</div>
                        <textarea class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" cols="20" rows="10" v-model="form.suggestion"></textarea>
                    </div>
                    <div class="form-group mb-5">
                        <div ref="recaptcha" class="g-recaptcha" :data-sitekey="siteKey"></div>
                    </div>
                    <div class="form-group mb-5 flex flex-col md:flex-row">
                        <button type="submit" class="bg-primary text-white mb-2 text-xs font-semibold rounded-sm py-3 px-4 duration-300 mr-2 flex justify-center items-center">
                            <mdicon name="send" size="20" class="mr-1 cursor-pointer" />Kirim</button>
                        <button type="Reset" class="bg-secondary text-white mb-2 text-xs font-semibold rounded-sm py-3 px-4 duration-300 mr-2 flex justify-center items-center" @click="clear">
                            <mdicon name="restore" size="20" class="mr-1 cursor-pointer" /> Reset Form</button>
                    </div>
                </form>
            </div>
            <div class="right hidden md:block">
                <div class="image">
                    <img :src="background" alt="Kritik & Saran">
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { masterSiteKey } from '@/main.js';
export default {
    name: 'ClientContentKritik',

    data() {
        return {
            siteKey: masterSiteKey,
            recaptchaWidgetId: null,
            form: {
                name: '',
                city: '',
                address: '',
                email: '',
                phone: '',
                suggestion: ''
            },
            defaultform: {
                name: '',
                city: '',
                address: '',
                email: '',
                phone: '',
                suggestion: ''
            },
            error: [],
            background: null
        };
    },

    mounted() {
        this.getImage();
        this.loadRecaptcha();
    },

    methods: {
        loadRecaptcha() {
            if (window.grecaptcha) {
                this.recaptchaWidgetId = grecaptcha.render(this.$refs.recaptcha, {
                    sitekey: this.siteKey,
                    callback: this.recaptchaCallback
                });
            } else {
                setTimeout(this.loadRecaptcha, 100);
            }
        },
        recaptchaCallback(response) {
            console.log('reCAPTCHA verified:', response);
        },
        submitForm() {
            // Handle form submission
            const response = grecaptcha.getResponse(this.recaptchaWidgetId);
            if (!response) {
                this.$notify({
                    title: "Error",
                    type: "error",
                    text: "Please verify reCAPTCHA"
                });
                return;
            } else {
                let formData = new FormData();
                formData.append('name', this.form.name);
                formData.append('address', this.form.address);
                formData.append('city', this.form.city);
                formData.append('phone', this.form.phone);
                formData.append('suggestion', this.form.suggestion);
                formData.append('email', this.form.email);

                this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                this.axios.post('api/storekritik', formData).then(() => {
                    this.clearform();
                    this.$notify({
                        title: "Kritik Dan Saran Terkirim",
                        type: "success",
                        text: "Terimakasih Sudah Atas Kritik dan Saran Anda"
                    });
                }).catch(error => {
                    this.error = error.response.data
                }).finally(() => {
                    grecaptcha.reset(this.recaptchaWidgetId);
                })
            }
            // Proceed with form submission
        },

        clearform: function () {
            this.form = Object.assign({}, this.defaultForm);
        },

        getImage() {
            // this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/getgeneral').then((response) => {
                this.background = response.data.suggestionpath
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss"
</style>
